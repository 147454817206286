import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page3 } from './data';

import { HashRouter as Router, Link } from "react-router-dom";

export default function Page3(props) {
  const renderDesc = (descriptions) => {
    const descHtml= descriptions.map((item, i) => (
      <p key={i.toString()}>
        {item}
      </p>
    ));
    return descHtml;
  };
  const children = page3.map((card, i) => (
    <div className="one-card" key={i.toString()}>
      <div className="card-content">
        <img src={card.img} alt="" />
        <p className="card-title">{card.title}</p>
        <div className="caed-detail">{renderDesc(card.descriptions)}</div>
      </div>
    </div>
  ));

  return (
    <section id="FAQ" className={`page-wrapper page3 text-center ${!props.isMobile && 'pc-page3'}`}>
      {
        !props.isMobile ? (
          <div>
            <div className={['topTip', props.isMobile && 'toopTip-mobile'].join(' ')}>
              <div className="top">
                <h2>FAQ</h2>
                <p>El equipo de YaCredito ha compilado una lista</p>
                <p>de dudas comunes que esperan que le ayuden.</p>
              </div>
            </div>
            <QueueAnim
                type="bottom"
                className="page"
                key="page3"
                component={Row}
              >
              {children}
            </QueueAnim>
          </div>
        ) : (
          <div>
            <div className={['topTip', props.isMobile && 'toopTip-mobile'].join(' ')}>
            <div className="top">
              <h2>FAQ</h2>
              <p>El equipo de YaCredito ha compilado una lista</p>
              <p>de dudas comunes que esperan que le ayuden.</p>
            </div>
          </div>
          <div className="page3-mobile-content">
            
            {/* <p className="page3-title">{'FAQ'}</p>
            <p className="page3-subtitle">{'OKPeso’s team has compiled a list of common misgivings that they hope will help you.'}</p> */}
            <div className="one-card">
              <div className="common-card">
                <img src={page3[0].img} alt="" />
                <p className="card-title">{page3[0].title}</p>
                <div className="card-detail">{renderDesc(page3[0].descriptions)}</div>
              </div>
            </div>
            <div className="one-card">
              <div className="common-card">
                <img src={page3[1].img} alt="" />
                <p className="card-title">{page3[1].title}</p>
                <div className="card-detail">{renderDesc(page3[1].descriptions)}</div>
              </div>
            </div>
            <div className="one-card">
              <div className="common-card last-card">
                <img src={page3[2].img} alt="" />
                <p className="card-title">{page3[2].title}</p>
                <div className="card-detail">{renderDesc(page3[2].descriptions)}</div>
              </div>
            </div>
            <div className="mobile-common-footer">
              <p className="title">Regulaciones</p>
              <div className="list-content regulation">
              <Router>
                <Link to="/privacyAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <span>《Acuerdo de Privacidad》</span>
                </Link>
                <Link to="/serviceAgreement" target="_blank" rel="noopener noreferrer" className="one-item" >
                    <span>《Acuerdo de Servicio》</span>
                </Link>
              </Router>
              </div>
            </div>
          </div>
          </div>
        )
      }

    </section>
  );
}
