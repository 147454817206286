import React from 'react';
import { HashRouter as Router, Link } from "react-router-dom";
// import websiteFooterBg from '../assets/imgs/footerBgm.png';
import fottOne from '../assets/imgs/fottOne.png';
import fottAddress from '../assets/imgs/fottAddress.png';
import fottmobile from '../assets/imgs/fottmobile.png';
import fottmobileAddress from '../assets/imgs/fottmobileAddress.png';

function Footer(props) {
  if(props.isMobile) {
    return (
      <footer id="footer" className="footer-wrapper footer-wrapper-mob">
      <div className="footer-bar" style={{background: '#fdb742'}}>
        {/* <img src={websiteFooterBg} className="footerBgImg" alt="footer" /> */}
        <div className="footer-content">
          <div className="one-center">
            <div>YACREDITO S.A.C.</div>
            <div style={{marginTop: '18px'}}>
              <img src={fottmobile} className="contImg" alt='fottmobile'/>
              <p>service@YaCredito.com</p>
            </div>
            <div style={{marginTop: '20px'}}>
              <div><img style={{marginLeft: '5px'}} src={fottmobileAddress} className="contImg" alt='fottmobileAddress'/></div>
              <p style={{lineHeight: '16px'}}>Av. Javier Prado Este, San isidro, Lima, Lima</p>
            </div>
            {/* <div>
              <img src={phones} className="contImg" alt='phones'/>
              <p>+51997938924</p>
            </div>
            <div>
              <img src={adress} className="contImg" alt='phones'/>
              <p>AV. REPUBLICA DE PANAMA NRO. 3535 INT. 403 - LIMA LIMA SANISIDRO</p>
            </div> */}
            {/* <div>
              <svg>
                <use xlinkHref="#iconaddress" />
              </svg>
              <p>2206, 88 Corporate Center, Sedeño,makati Metro Manila, Philippines</p>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
    )
  }
  return (
    <footer id="footer" className="footer-wrapper footer-wrapper-pc">
      <div className="">
        {/* <img src={websiteFooterBg} className="footerBgImg" alt="footer" /> */}
        <div className="footer-content">
          <div className="one-center mdr-card">
            <h2>YACREDITO S.A.C.</h2>
            <div>
              <img src={fottOne} className="contImg" alt='fottOne'/>
              <p>service@yacredito.com</p>
            </div>
            <div style={{marginTop: '20px'}}>
              <div>
              <img src={fottAddress} className="contImg" alt='fottAddress'/>
              </div>
              <p style={{lineHeight: '16px'}}>Av. Javier Prado Este, San isidro, Lima, Lima</p>
            </div>
            {/* <div>
              <img src={phones} className="contImg" alt='phones'/>
              <p>+51997938924</p>
            </div>
            <div>
              <img src={adress} className="contImg" alt='phones'/>
              <p>AV. REPUBLICA DE PANAMA NRO. 3535 INT. 403 - LIMA LIMA SANISIDRO</p>
            </div> */}
            {/* <img src={adress} className="contImg"  alt='adress'/> <span style={{lineHeight: '1.5vw'}}></span> */}
          </div>
          <div className="one-center">
            <h2 className="h2Marb">Regulaciones</h2>
            <Router>
              <h3>
              <Link to="/privacyAgreement" target="_blank">
              《Acuerdo de Privacidad》
              </Link>
              </h3>
              <h3>
              <Link to="/serviceAgreement" target="_blank">
              《Acuerdo de Servicio》
              </Link>
              </h3>
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2020 |  YACREDITO S.A.C. | All Rights Reserved
      </div>
    </footer>
  );
}


export default Footer;
